<template>
  <div class="main-content">
    <page-title name="Категории документов" route_name="documents"/>
    <div class="section">
      <div class="checkout">
        <div class="checkout-line">
          <label class="label-tp-1">Поиск докуметов</label>
          <input type="search" class="search-input input-tp-1" placeholder="Поиск документа по названии" v-model="searchDocument">
        </div>
      </div>
      <div class="documents_categories_wrapper" v-if="!searchDocument.length">
        <div :key="index" class="col-one-third documents_categories" v-for="(documents, index) in $store.state.documents">
          <article class="post post-tp-8">
            <h3 class="title-5">
              <router-link :to="{name: 'open_category', params: {catid: documents.id}}">{{ documents.title || ''}}</router-link>
            </h3>
            <div class="meta-tp-2">
              <div class="date" v-if="documents.updated_at"><span>{{documents.get_document_content.length || '0'}} {{documents.get_document_content.length > 1 ? 'документа' : 'документ'}}</span></div>
            </div>
          </article>
        </div>
      </div>
      <div class="" v-if="searchDocument.length">
        <div class="documents-open_items documents-open_category">

          <div v-if="filteredDocuments.length">
            <p v-for="(item, index) in filteredDocuments" :key="index">
              <router-link :to="{ name: 'open_documents', params: {catid: item.category_id, id: item.id}}">
                {{item.title || 'Нет названия'}}
              </router-link>
            </p>
          </div>
          <h4 v-if="!filteredDocuments.length">Нет документов соответсвующих поисковому запросу</h4>
        </div>
      </div>

      <!--<div class="page-nav" v-if="$store.state.documents.total > $store.state.documents.per_page">
        <a @click.prevent="parsePage($store.state.documents.prev_page_url)" class="pn-item" href="#">
          <i class="page-nav-prev-ic"></i>
        </a>
        <a :class="'pn-item mb-pt-hide'+ ($store.state.documents.current_page === 1 ? ' current' : '')" v-for="(doc_category, index) in $store.state.documents" href="#" :key="index">
          {{ doc_category }}
        </a>
        <a :class="'pn-item mb-pt-hide'+ ($store.state.documents.current_page === 1 ? ' current' : '')" @click.prevent="parsePage($store.state.documents.current_page === 1 ? null : $store.state.system_data.base_url + '/api/modocuments?page=' + $store.state.documents.one)" href="#">
          {{ $store.state.documents.one }}
        </a>
        <a :class="'pn-item mb-pt-hide' + (($store.state.documents.current_page !== 1 && $store.state.documents.current_page !== $store.state.documents.last_page) ? ' current' : '')" @click.prevent="parsePage(($store.state.documents.current_page !== 1 && $store.state.documents.current_page !== $store.state.documents.last_page) ? null : $store.state.system_data.base_url + '/api/modocuments?page=' + $store.state.documents.two)" href="#">
          {{ $store.state.documents.two }}
        </a>
        <a :class="'pn-item mb-pt-hide' + ($store.state.documents.current_page === $store.state.documents.last_page ? ' current' : '')" @click.prevent="parsePage($store.state.documents.current_page === $store.state.documents.last_page ? null : $store.state.system_data.base_url + '/api/modocuments?page=' + $store.state.documents.three)" href="#" v-if="$store.state.documents.last_page > 2">
          {{ $store.state.documents.three }}
        </a>
        <a @click.prevent="parsePage($store.state.documents.next_page_url)" class="pn-item" href="#">
          <i class="page-nav-next-ic"></i>
        </a>
      </div>-->
    </div>
  </div>
</template>

<script>
import PageTitle from '../components/layouts/PageTitle.vue';

export default {
  name: 'Documents',
  components: { PageTitle },
  async asyncData (context) {
    await context.store.dispatch('documentsListPage');
  },
  methods: {
    async parsePage (url) {
      if (url !== null) {
        await this.$store.dispatch('documentsListPage', url);
      }
    }
  },
  metaInfo () {
    return this.$seo('Документы');
  },
  data () {
    return {
      searchDocument: ''
    };
  },
  computed: {
    filteredDocuments () {
      let filtered = [];
      this.$store.state.documents.filter(category => {
        let docs = category.get_document_content.filter(document => {
          return document.title.toLowerCase().includes(this.searchDocument.toLowerCase());
        });
        filtered = filtered.concat(docs);
      });
      return filtered;
    }
  }
};
</script>
